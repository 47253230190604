import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Column, Table2, RowHeaderCell } from '@blueprintjs/table';
import styles from './PciTableView.module.scss';
import { PCI_PALETTE } from '../../../const/pciConstants';
import { getSeverityIndexByPci } from '../../../utils/pciUtils';
const cols = [
    'owner_id',
    'section_id',
    'road_name',
    'conventional_rating',
    'pci_rating',
    'length~meters',
    'area~meters',
    'length~feet',
    'area~feet',
    'pothole_LOW',
    'pothole_MODERATE',
    'pothole_HIGH',
    'pothole_OVERALL',
    'crack_alligator_LOW',
    'crack_alligator_MODERATE',
    'crack_alligator_HIGH',
    'crack_alligator_OVERALL',
    'surface_deform_LOW',
    'surface_deform_MODERATE',
    'surface_deform_HIGH',
    'surface_deform_OVERALL',
    'surface_defect_LOW',
    'surface_defect_MODERATE',
    'surface_defect_HIGH',
    'surface_defect_OVERALL',
    'patch_LOW',
    'patch_MODERATE',
    'patch_HIGH',
    'patch_OVERALL',
    'crack_len_LOW',
    'crack_len_MODERATE',
    'crack_len_HIGH',
    'crack_len_OVERALL',
    'nondefect_LOW',
    'nondefect_MODERATE',
    'nondefect_HIGH',
    'nondefect_OVERALL',
    'other_cracks_LOW',
    'other_cracks_MODERATE',
    'other_cracks_HIGH',
    'other_cracks_OVERALL',
    // Disable these columns for now:
    // 'geometry',
    // 'platform_urls',
    // 'frames_frontal',
    // 'frames_bev',
];
export const PciTableView = ({ data }) => {
    const basicCellRenderer = (rowIndex, columnIndex) => {
        const row = data[rowIndex];
        const columnName = cols[columnIndex];
        const value = row ? String(row[columnName]) : '';
        const style = {};
        if (columnName === 'conventional_rating') {
            const pci_value = row.pci_rating;
            const severityIndex = getSeverityIndexByPci(pci_value);
            style.backgroundColor = `rgb(${PCI_PALETTE[severityIndex].join(',')})`;
            // Alternative color palette
            // const [r, g, b] = PCI_PALETTE[severityIndex];
            // style.backgroundColor = `rgb(${Math.round(r * 0.8)}, ${Math.round(g * 0.8)}, ${Math.round(b * 0.8)})`;
            style.color = 'white';
        }
        return row ? (_jsx("div", { className: `${rowIndex % 2 === 0 ? styles.oddCell : styles.cell}`, style: style, children: value })) : undefined;
    };
    const columnWidths = useMemo(() => {
        return cols.map((col) => {
            const maxLength = Math.max(col.length, ...data.map((row) => String(row[col] || '').length));
            return Math.min(Math.max(maxLength * 8, 50), 350);
        });
    }, [data]);
    // Functions to format the column names
    function titleCase(str) {
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());
    }
    const formatUnits = (value) => {
        if (typeof value === 'string') {
            if (value.includes('Length')) {
                return value.replace(/~meters/g, ' m').replace(/~feet/g, ' ft');
            }
            if (value.includes('Area')) {
                return value.replace(/~meters/g, ' m²').replace(/~feet/g, ' ft²');
            }
        }
        return value;
    };
    return (_jsx(Table2, { numRows: data.length, numFrozenColumns: 3, columnWidths: columnWidths, className: styles.table, enableColumnInteractionBar: true, enableColumnResizing: true, enableRowResizing: false, defaultRowHeight: 30, rowHeaderCellRenderer: (rowIndex) => (_jsx(RowHeaderCell, { children: _jsx("div", { className: styles.rowHeader, children: rowIndex }) })), children: cols.map((el, index) => (_jsx(Column, { name: el, cellRenderer: basicCellRenderer, columnHeaderCellRenderer: (columnIndex) => (_jsx("div", { className: styles.headerCell, children: formatUnits(titleCase(cols[columnIndex].replaceAll('_', ' '))) })) }, el))) }));
};
